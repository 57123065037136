import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

const Root = css`
  background: #3FA9F5;
  border: none;
  color: #fff;
  border-radius: 8px;
  padding: 15px 30px;
  font-size: 20px;
  box-shadow: var(--shadow-on-bg);
  font-weight: 300;
  display: block !important;
  margin: 15px 0;
  transition: transform .3s,
              box-shadow .3s ease;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  &:hover {
    box-shadow: var(--shadow-large);
    transform: scale(1.03);
  }
  &:active {
    background: #3286C1;
    transform: scale(1.03);
  }
  &:before {
    border-radius: 8px;
  }
  @media screen and (max-width: 350px) {
    font-size: 15px;
  }
  @media (min-width: 768px) {
    width: 400px;
  }
  @media (min-width: 992px) {
    display: inline-block;
    width: auto;
  }
`

const RootButton = styled.button`
  ${Root};
`;

const RootLink = styled(Link)`
  ${Root};
`;

const Button = (props) => {
  const {isLink, className, ...other} = props;
  if(isLink) {
    return (
      <RootLink {...other} className={`hvr-out ${className}`}>
        {props.children}
      </RootLink>
    );
  }
  return (
    <RootButton {...other} className={`hvr-out ${className}`}>
      {props.children}
    </RootButton>
  );
}

export default Button
