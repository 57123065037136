import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { graphql, Link, navigate } from 'gatsby'
import Layout from '../../components/Layout'
import Button from '../../components/Button'
import { TechnologyPill } from '../../components/SharedStyledComponents'

const ProjectsLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`

const ProjectCard = styled.div`
  background: var(--content-bg);
  padding: 30px;
  border-radius: 8px;
  box-shadow: var(--shadow-on-bg);
  border: 2px solid var(--input-border-dark);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 0 0;
  width: 100%;
  &:first-child {
    margin: 0;
  }
  >p {
    font-weight: 300;
    font-size: 18px;
    line-height: 1.4;
    text-align: justify;
    flex: 1;
  }
  @media (min-width: 610px) {
    padding: 40px;
    width: 100%;
    max-width: 500px;
    margin: 0 20px 40px;
    &:first-child {
      margin: 0 20px 40px;
    }
    >p {
      margin: 30px 0;
    }
  }
  @media screen and (max-width: 350px) {
    >p {
      font-size: 15px;
    }
  }
`

const ProjectPicture = styled(Img)`
  width: 70%;
  box-shadow: var(--shadow-on-bg);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
  @media (min-width: 425px) {
    width: 50%;
  }
  @media (min-width: 610px) {
    max-width: 144px;
    width: 144px;
    max-height: 144px;
    height: 144px;
    margin-bottom: 0;
    margin-right: 40px;
  }
`

const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  h1 {
    margin: 0;
    font-weight: 400;
    font-size: 30px;
    text-align: center;
  }
  @media (min-width: 610px) {
    flex-direction: row;
    align-items: initial;
    margin: 0;
    h1 {
      text-align: left;
    }
  }
  @media screen and (max-width: 350px) {
    h1 {
      font-size: 25px;
    }
  }
`

const TechnologiesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  flex-basis: 20px;
  margin-top: 10px;
  max-width: 250px;
  @media (min-width: 610px) {
    justify-content: flex-start;
  }
`

const ButtonWrapper = styled(Button)`
  margin: 0;
  padding: 10px 20px;
  width: 100%;
  @media (min-width: 610px) {
    width: initial;
  }
`

const Projects = ({ data }) => {

  const projects = data.allFile.edges

  return (
    <Layout bottomMargin={false}>
      <ProjectsLayout>
        {projects.map(({ node }) => {
          const frontmatter = node.childMarkdownRemark.frontmatter
          return (
            <ProjectCard key={frontmatter.title}>
              <CardHeader>
                <ProjectPicture fluid={frontmatter.thumbnail.childImageSharp.fluid} alt="Project thumbnail"/>
                <div>
                  <h1>{frontmatter.title}</h1>
                  <TechnologiesContainer>
                    {frontmatter.technologies.map((tech, index) => (
                      <TechnologyPill key={index}>{tech}</TechnologyPill>
                    ))}
                  </TechnologiesContainer>
                </div>
              </CardHeader>
              <p>{frontmatter.description}</p>
              <ButtonWrapper to={node.childMarkdownRemark.fields.slug} state={{modal: true}} isLink>
                Read more
              </ButtonWrapper>
            </ProjectCard>
          )
        })}
      </ProjectsLayout>
    </Layout>
  )
}

export default Projects

export const pageQuery = graphql`
  query {
    allFile(filter: {internal: {mediaType: {eq: "text/markdown"}}, sourceInstanceName: { eq: "projects" } }) {
      edges {
        node {
          childMarkdownRemark {
            fields {
              slug
            }
            frontmatter {
              title
              thumbnail {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              technologies
              description
            }
          }
        }
      }
    }
  }
  
`
