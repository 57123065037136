import styled from 'styled-components'

export const TechnologyPill = styled.div`
  background: var(--alt);
  color: var(--t-pill-text);
  padding: 5px 15px;
  border-radius: 30px;
  margin-right: 10px;
  margin-top: 10px;
  font-size: 15px;
  &:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 350px) {
    font-size: 12px;
  }
`
